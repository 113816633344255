export const maze = [
    'a21e', 'c201', '3201', 'a21a', 'd014', '7004', '3040', '1200',
    '6000', '7104', '3120', '1200', '1218', '8040', '2010', '2040',
    '8010',
];

export const mazeAlt = [
    '6000', '6100', 'a222', 'c201', '3201', 'a21e', 'd014', '7004',
    '3040', '1204', '6000', '7104', '3120', '1204', '121c', '8040',
    '2010', '2040', '8010',
];
